import { DefaultSeo, LocalBusinessJsonLd, LogoJsonLd } from "next-seo";
import Head from "next/head";
import Footer from "./Footer";
import Header from "./Header";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => (
  <div className="subpixel-antialiased bg-white">
    <Head>
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <DefaultSeo
      title="Sinan Cem Ünlü | Ünlü Hukuk Bürosu"
      openGraph={{
        type: "website",
        locale: "tr_TR",
        url: "https://www.sinancemunlu.com/",
        site_name: "Sinan Cem Ünlü | Ünlü Hukuk Bürosu",
        description: "LL.M. Av. Sinan Cem Ünlü",
      }}
    />
    <LogoJsonLd
      logo="https://www.sinancemunlu.com/logo.jpg"
      url="https://www.sinancemunlu.com"
    />
    <LocalBusinessJsonLd
      type="LegalService"
      id="http://www.sinancemunlu.com/"
      name="Sinan Cem Ünlü | Ünlü Hukuk Bürosu"
      description="LL.M. Av. Sinan Cem Ünlü"
      url="http://www.sinancemunlu.com/"
      telephone="+905543800760"
      address={{
        streetAddress: "Bağdat Cd. No:233 K:3 D:5",
        addressLocality: "Göztepe",
        addressRegion: "İstanbul",
        postalCode: "34730",
        addressCountry: "TR",
      }}
      geo={{
        latitude: "40.97183648300698",
        longitude: "29.0598022695708",
      }}
      images={["https://www.sinancemunlu.com/logo.jpg"]}
      openingHours={[
        {
          opens: "09:00",
          closes: "18:30",
          dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        },
      ]}
    />
    <Header />
    {children}
    <Footer />
  </div>
);

export default Layout;
