import Image from "next/image";
import logoPic from "../public/logo.png";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container px-4 mx-auto">
        <div className="max-w-4xl pt-24 mx-auto pb-11">
          <div className="block mb-5 md:mx-auto max-w-max">
            <Image
              src={logoPic}
              width={150}
              height={58}
              alt="Sinan Cem Ünlü Logo"
              placeholder="blur"
            />
          </div>
        </div>
      </div>
      <div className="border-b border-coolGray-100"></div>
      <div className="container px-4 mx-auto">
        <p className="py-10 text-lg font-medium text-center md:pb-20 md:text-xl text-coolGray-400">
          © {year} Sinan Cem Ünlü. Tüm hakları saklıdır.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
